import { useState, useEffect } from "react";
import { BaseDialog } from "./BaseDialog";
import { trackEvent, trackSelect } from "../../lib/utils/analytics";
import teamMap from "../../lib/utils/teamMap";
import usePersistentStore from "../../lib/store/PersistentStore";
import useGameStore from "../../lib/store/GameStore";
import { shallow } from "zustand/shallow";
import { MiniGrid } from "./PickHistoryDialog";
import { Divider } from "./Divider";
import { playerList, hasImage } from "../../lib/utils/playerList";
import { rarityScheme } from "../../lib/utils/rarityScheme";
import { CountUpRarity } from "../Board";
import { calcRarity } from "../../lib/utils/calcRarity";
import { gridLevelNumber } from "../../lib/utils/gridLevels";

function FriendDialog({ isOpen, closeModal, attempt, rank }) {
  return (
    <BaseDialog
      isOpen={isOpen}
      dark={true}
      closeModal={closeModal}
      modalBody={<ModalBody attempt={attempt} />}
    />
  );
}

function ModalBody({ attempt }) {
  const [guessesLeft] = usePersistentStore(
    (state) => [state.guessesLeft],
    shallow
  );

  return (
    <div className="-mx-4 -mb-4 -mt-6 flex flex-col items-center justify-center bg-neutral-100 text-center">
      <div
        className={`bg-fit flex w-full cursor-pointer items-center justify-between overflow-hidden ${attempt.user.favTeam.abbr.toLowerCase()} bg-gradient-to-r from-black/70 bg-cover px-4 py-4 font-medium text-slate-200 focus-visible:z-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-[#59d185] dark:border-gray-950 dark:hover:bg-gray-700 sm:px-6 sm:py-4`}
      >
        <div id="name" className="flex items-center text-gray-800">
          <img
            className="mr-3 h-5 w-5 rounded-full outline outline-1 outline-white sm:mr-3 sm:h-6 sm:w-6"
            src={`/img/logos/512/${attempt.user.favTeam.id}.png`}
            alt={`${attempt.user.favTeam.city} colours`}
          ></img>
          <div className="flex flex-col">
            <div className="line-clamp-1 text-ellipsis text-left text-md font-medium text-white antialiased sm:text-base">
              {attempt.user.displayName}
            </div>
            {/* <div className="line-clamp-1 text-ellipsis text-left text-xxs text-white/70 antialiased group-[.user-row]:text-slate-100 sm:text-xs">
              Joined Oct '23
            </div> */}
          </div>
        </div>
      </div>
      <DropHeader attempt={attempt} />
      <div className="mb-4 mt-2 flex min-h-[12rem] flex-col justify-center">
        {guessesLeft <= 0 ? (
          <FriendGrid attempt={attempt} />
        ) : (
          <div class="px-6">
            <div class="flex justify-center font-semibold uppercase text-gray-700 dark:text-gray-200">
              View friend's grid
            </div>
            <div class="flex justify-center py-4 text-sm text-gray-700 dark:text-gray-400">
              <p>Play todays grid, then see other's selections</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function DropHeader({ attempt }) {
  const bg = attempt.user.favTeam.abbr.toLowerCase();

  return (
    <>
      <div className={`h-0.5 w-full bg-gradient-to-r from-white/20 ${bg}`} />
      <div className="relative flex h-7 items-center justify-center text-xs font-semibold uppercase tracking-wide text-white antialiased sm:h-8 sm:text-sm">
        <div className="absolute z-20 flex h-full items-center justify-center whitespace-nowrap bg-gray-600 text-[white]">
          Gridley {gridLevelNumber}
        </div>
        <div className="absolute flex h-full items-center justify-center whitespace-nowrap bg-gray-600 px-1 text-[white] before:absolute before:left-[-20px] before:top-0 before:z-0 before:block before:h-full before:w-[50px] before:skew-x-[35deg] before:rounded-[0px_6px] before:bg-gray-600 before:content-['_'] after:absolute after:right-[-20px] after:top-0 after:z-10 after:block after:h-full after:w-[50px] after:skew-x-[-35deg] after:rounded-[6px_0px] after:bg-gray-600 after:content-['_']">
          Gridley {gridLevelNumber}
        </div>
      </div>
    </>
  );
}

export function FriendGrid({ attempt }) {
  const [grid, globalStats] = useGameStore((state) => [
    state.grid,
    state.globalStats,
  ]);

  const rarity = calcRarity(attempt.answers, globalStats);
  const rarityDisplay = isNaN(rarity) ? 900 : rarity;

  return (
    <>
      <div className="-ml-4 -mr-4 flex justify-center">
        <div>
          <div className="flex">
            <div className="w-21 sm:-ml-10 sm:w-32"></div>
            <ColHeader item={grid.hItems[0]} />
            <ColHeader item={grid.hItems[1]} />
            <ColHeader item={grid.hItems[2]} />
          </div>
          <div className="flex items-center">
            <div>
              <RowHeader item={grid.vItems[0]} />
              <RowHeader item={grid.vItems[1]} />
              <RowHeader item={grid.vItems[2]} />
            </div>
            <div className="grid grid-cols-3 overflow-hidden rounded-xl border bg-white dark:border-gray-950 dark:bg-gray-800">
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={1} col={3} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={2} col={3} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={1} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={2} />
              <FriendCell attempt={attempt} selectedTab="my" row={3} col={3} />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2 mt-6 sm:mb-4 md:mt-8">
        <div className="text-center text-sm font-semibold uppercase text-gray-600 dark:text-gray-300 sm:text-sm">
          Rarity Score
        </div>
        <div className="flex items-center justify-center ">
          <div className="relative min-w-[8rem] px-8 text-center text-5xl font-semibold leading-none tracking-tight sm:text-6xl">
            <CountUpRarity rarity={rarityDisplay} />
          </div>
        </div>
      </div>
    </>
  );
}

function FriendCell({ attempt, selectedTab, row, col }) {
  const [globalStats] = useGameStore((state) => [state.globalStats]);
  const [cardMode] = usePersistentStore((state) => [state.cardMode]);

  const storedCorrectGuessId = attempt.answers[row - 1][col - 1];
  const displayPlayer = playerList.find(
    (player) => player.id === storedCorrectGuessId
  );
  const correctAnswersPlayerMap =
    globalStats?.correctAnswersPlayerMap[row - 1][col - 1];
  const selectedPct =
    displayPlayer && correctAnswersPlayerMap
      ? (correctAnswersPlayerMap[displayPlayer.id] / globalStats?.completed) *
        100
      : 0.1;

  const scheme = rarityScheme(selectedPct);
  //  const bg = cardMode ? scheme.class : "bg-[#59d185]";
  const bg = "bg-slate-400";

  return (
    <div
      className={`flex h-21 w-21 items-center justify-center overflow-hidden border-slate-500 bg-cover bg-center ${
        col < 3 && "border-r"
      } ${
        row < 3 && "border-b"
      } ${bg} text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl`}
    >
      {displayPlayer && (
        <div className="overflow group relative flex h-full w-full items-center justify-center">
          {!isNaN(selectedPct) && (
            <div className="absolute right-0 top-0 flex h-4 items-center rounded-bl-lg bg-gray-900 pl-1.5 pr-1 text-xxs text-white opacity-95 sm:h-5 sm:pr-1.5 sm:text-xs md:h-6">
              {selectedPct < 1
                ? selectedPct.toFixed(1)
                : selectedPct.toFixed(0)}
              %
            </div>
          )}

          {displayPlayer.last >= 2022 ? (
            <img
              src={`https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/${displayPlayer.last}014/${displayPlayer.c}.png`}
              className={`absolute ${
                selectedTab == "no_spoilers" ? "opacity-80 contrast-0" : ""
              }`}
            />
          ) : displayPlayer.last >= 2019 ? (
            <img
              src={`https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/${displayPlayer.last}014/${displayPlayer.c}.png`}
              className={`absolute -top-1 scale-110 md:-top-2 ${
                selectedTab == "no_spoilers" ? "opacity-80 contrast-0" : ""
              }`}
            />
          ) : displayPlayer.last >= 2013 ? (
            <img
              src={`https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/${displayPlayer.last}014/${displayPlayer.c}.png`}
              className={`absolute -top-1 sm:top-0 sm:scale-105 ${
                selectedTab == "no_spoilers" ? "opacity-80 contrast-0" : ""
              }`}
            />
          ) : hasImage.includes(displayPlayer.id) ? (
            <img
              src={`/img/headshots/${displayPlayer.id}-${displayPlayer.name
                .replaceAll(" ", "-")
                .toLowerCase()}.png`}
              className={`absolute h-20 sm:h-28 ${
                selectedTab == "no_spoilers" ? "opacity-80 contrast-0" : ""
              }`}
            />
          ) : (
            <img
              src="/img/silhouette.png"
              className={`absolute -top-1 mb-5 sm:mb-1 md:-top-2 md:mb-5 ${
                selectedTab == "no_spoilers" ? "opacity-80 contrast-0" : ""
              }`}
            />
          )}
          {selectedTab != "no_spoilers" && (
            <>
              {/* <div className="absolute bottom-0 left-0 right-0">
              {selectedTab == "my" && !undoUsed && (
                <div
                  className={`line-clamp-1 ${
                    undoModeActive ? "flex" : "hidden"
                  } h-24 items-center justify-center bg-gradient-to-b from-gray-900/0 from-0% to-gray-900/95 py-px text-xxs text-white sm:h-36 sm:text-xs sm:group-hover:flex md:h-48 md:text-sm`}
                >
                  <svg
                    className="mr-2 h-4 w-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 940 1000"
                  >
                    <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
                  </svg>{" "}
                  Undo
                </div>
              )}
            </div> */}
              <div className="absolute bottom-0 left-0 right-0">
                {/* <div className="line-clamp-1 hidden h-40 items-center justify-center bg-gradient-to-b from-gray-900/0 from-0% to-gray-900/95 py-px text-xxs text-white group-hover:flex  sm:text-xs md:text-sm">
              <svg
                className="mr-2 h-4 w-4 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 940 1000"
              >
                <path d="M532 90c113.333 0 209.667 40 289 120s119 176.667 119 290-39.667 210-119 290-175.667 120-289 120c-92 0-176-29.333-252-88l70-76c54.667 40 115.333 60 182 60 84 0 156-30 216-90s90-132 90-216c0-85.333-30-158-90-218s-132-90-216-90c-82.667 0-153.667 28.667-213 86s-90.333 127.333-93 210h142L184 694 0 488h124c2.667-110.667 43.667-204.667 123-282S421.333 90 532 90m-36 190h70v204l130 130-50 50-150-150V280" />
              </svg>{" "}
              Undo
            </div> */}
                <div className="truncate whitespace-nowrap bg-gray-900 px-px py-px text-xxs text-white opacity-95 sm:text-xs">
                  {displayPlayer.name.includes(" (")
                    ? displayPlayer.name.substring(
                        0,
                        displayPlayer.name.indexOf(" (")
                      )
                    : displayPlayer.name}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

function ColHeader({ item }) {
  const type = item.id.length > 2 ? "award" : "team";

  return (
    <div className="flex h-16 w-21 items-center justify-center text-xs sm:h-20 sm:w-28 ">
      {type == "award" ? (
        <AwardHeader item={item} pos="col" />
      ) : (
        <TeamLogo item={item} />
      )}
    </div>
  );
}

function RowHeader({ item }) {
  const type = item.id.length > 2 ? "award" : "team";

  return (
    <div className="flex h-21 w-21 items-center justify-center text-xs  sm:-ml-10 sm:h-28 sm:w-32">
      {type == "award" ? (
        <AwardHeader item={item} pos="row" />
      ) : (
        <TeamLogo item={item} />
      )}
    </div>
  );
}

export function TeamLogo({ item }) {
  const team = teamMap[item.id];

  return (
    <div>
      <img
        className="h-11 w-11 rounded-full sm:h-14 sm:w-14"
        src={`/img/logos/512/${team.logoId}.png`}
        alt={`${team.full_name} colours`}
      ></img>
    </div>
  );
}

export function AwardHeader({ item }) {
  return (
    <div className="font-display flex h-20 flex-col items-center justify-center text-center text-xs font-bold leading-tight text-gray-800 dark:text-white sm:h-28 sm:text-sm  ">
      {item.imgUrl ? (
        <img
          className="mb-1 mt-1 h-16 w-16 sm:h-24 sm:w-24"
          src={item.imgUrl}
          alt={item.title}
        ></img>
      ) : (
        <div className="leading-tight">{item.title}</div>
      )}
      <div className="text-xxs uppercase text-gray-600 dark:text-gray-400 sm:text-xs">
        {item.subtitle}
      </div>
    </div>
  );
}

function StatBox({ title, value }) {
  return (
    <div className="mx-1 flex w-1/3 flex-col rounded-md bg-slate-200 py-2 md:pt-3">
      <div className="mb-1 flex h-8 flex-col justify-center px-4 text-xs font-normal leading-tight opacity-70 md:text-sm">
        {title}
      </div>
      <div className="text-2.5xl md:text-3xl">{value}</div>
    </div>
  );
}

function ChartBar({ label, value, total, mode }) {
  const highlight = value === mode;
  const pct = total == 0 ? 0 : Math.round((value / total) * 100);
  const widthPct = Math.round((value / mode) * 100) * 0.75;
  const barColor = highlight ? "bg-slate-300" : "bg-slate-300";
  return (
    <div className="flex flex-row items-center justify-start">
      <div className="inline-block w-5 text-sm">{label}</div>
      <div className={`inline-block h-5 w-2 ${barColor} my-1`}></div>
      <div
        className={`inline-block h-5 ${barColor} my-1 -ml-0.5 rounded-r-md`}
        style={{ width: `${widthPct}%` }}
      ></div>
      <div className="ml-1.5 inline-block w-8 text-left text-xs opacity-70">
        {pct}%
      </div>
    </div>
  );
}
function ModalCloseButton({ clickCallback }) {
  return (
    <button
      type="button"
      className="absolute right-1.5 -m-1.5 mr-2 rounded-full p-1.5 transition-colors duration-75 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#59d185] dark:text-gray-300 dark:hover:bg-gray-700"
      onClick={clickCallback}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  );
}

export default FriendDialog;
