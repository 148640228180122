import { useState } from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "./BaseDialog";

function CaptainsDialog({ modalTitle = "Take the Captains Challenge" }) {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={modalTitle}
        modalBody={<HowToPlayBody />}
        size="md"
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 space-y-4 px-2 pt-2 text-md sm:px-4">
      <div>
        Fantasy fans it's time to take the{" "}
        <a
          className="font-medium text-blue-600 hover:underline"
          href="https://captainschallenge.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=modal"
          target="_blank"
        >
          Captains Challenge
        </a>
        , the all-new game from Gridley!
      </div>

      <div className="flex w-full items-center overflow-hidden">
        <a
          className="font-medium text-blue-600 hover:underline"
          href="https://captainschallenge.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=modal"
          target="_blank"
        >
          <img src="/img/partners/captains/captains.png" />
        </a>
      </div>
      <div className="pl-0">
        <ul>
          <li>✅ &nbsp;Pick 5 players each week</li>
          <li>
            💯 &nbsp;Earn fantasy points{" "}
            <span className="hidden sm:inline-block">
              from real-life performances
            </span>
          </li>
          <li>
            📣 &nbsp;Share{" "}
            <span className="hidden sm:inline-block">your results</span> and
            beat the "experts"
          </li>
        </ul>
      </div>
      <div>
        Round 1 locks at 7:30pm AEDT, so{" "}
        <a
          className="font-medium text-blue-600 hover:underline"
          href="https://captainschallenge.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=modal"
          target="_blank"
        >
          get your picks in now!
        </a>
      </div>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default CaptainsDialog;
